<style lang="scss">
.full-screen-button {
  position: fixed;
  left: 50%;
  z-index: 2;
  bottom: 104px;
  transform: translateX(-50%);
  padding-left: 14px;
  padding-right: 14px;
  height: 32px;
  border: 2px solid #ffffff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  backdrop-filter: blur(5px);
  border:1px solid rgba(89, 87, 87, 1);

  .text {
    font-size: 14px;
    color:rgba(89, 87, 87, 1);

    margin-left: 15px;
  }

  .icon {
    width: 20px;
    height: 20px;
    background-image: url('./1.svg');
  }

  &:hover {
    // background: rgba(0, 0, 0, 0.5);
    // border: 2px solid #ffffff !important;
  }

  &:active {
    // border: 2px solid #007aff !important;
    // background: rgba(0, 0, 0, 0.5);
    .icon {
    }
  }

  &#active {
    .icon {
    background-image: url('./2.svg');

    }
    &:active {
      .icon {
      }
    }
  }
}
</style>
<template>
  <div class="structure">
    <div
      class="full-screen-button"
      v-show="showBtn"
      @click="setIsChecked"
      :id="isChecked ? 'active' : ''"
    >
      <div class="icon"></div>
      <div class="text" v-if="!isChecked" >  
        Dynamic Display</div>
      <div class="text" v-if="isChecked">Return</div>
    </div>
  </div>
</template>

<script>
import bus from "../../../../lib/bus";

export default {
  data() {
    return {
      isChecked: false,
      showBtn: true,
    };
  },
  watch:{
    "$store.state.mesh"(newV,oldV){
      bus.$emit("resetAllNow", oldV);
      this.isChecked=false
    }
  },  
  methods: {
    setIsChecked() {
      this.isChecked = !this.isChecked;
      this.showBtn = false;
      if (this.isChecked) {
        console.log(this.$store.state.mesh);
        bus.$emit("displayMesh", this.$store.state.mesh);
      } else {
        bus.$emit("unDisplayMesh", this.$store.state.mesh);
      }
      let timer = setTimeout(() => {
        this.showBtn = true;
        clearTimeout(timer);
      }, 2000);
    },
  },
  destroyed() {
    if (this.isChecked == true) {
      bus.$emit("resetAllNow");

    }
  },
  created(){
    bus.$emit("cameraToPosition2",2,this.$store.state.mesh)

  }
};
</script>
